import { Reducer } from 'redux';
import {
  ClearPrefillOfficerAction,
  SetOfficerPrefillAction
} from 'services/actions/storeActions';
import { IUser } from 'services/models/IUser';
import { SignUpFormModel } from 'models/CreateAccountModel';

type StoreState = {
  isOfficerPrefill: boolean;
  officer?: IUser;
  formData?: Partial<SignUpFormModel>;
  httpReferrer?: string;
  qr?: boolean;
  calculator?: boolean;
  prospectUUID?: string;
};

const initialState: StoreState = {
  isOfficerPrefill: false
};

type Actions = SetOfficerPrefillAction | ClearPrefillOfficerAction;

export const storeReducer: Reducer<StoreState, Actions> = (
  state = initialState,
  action: Actions
) => {
  switch (action.type) {
    case 'SET_OFFICER_ACTION':
      return {
        ...state,
        isOfficerPrefill: true,
        officer: action.payload.officer,
        formData: action.payload.formData,
        httpReferrer: action.payload.httpReferrer,
        qr: action.payload.qr,
        calculator: action.payload.calculator,
        prospectUUID: action.payload.prospectUUID
      };
    case 'CLEAR_PREFILL_OFFICER':
      return {
        isOfficerPrefill: false
      };
    default:
      return state;
  }
};
