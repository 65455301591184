import {
  ExpenseRequest,
  IExpensesBody,
  IHousingExpense
} from 'services/models/IExpenses';
import { ILoanStatus, ILoanUser } from 'services/models/ILoanStatus';
import { IRoles } from 'services/models/IRoles';
import { IExpenses, ILoanApplicationModel } from 'models/LoanApplicationModel';
import {
  getBorrowerFromLoanPending,
  getCoborrowersFromLoanPending
} from 'utils/commonUtils';

export const findBorrowerUser = (loan: ILoanStatus): ILoanUser => {
  return loan.loanUsers?.find((loanUser) => {
    return loanUser.role === IRoles.BORROWER;
  });
};

const parseNumber = (n?: number | string) => {
  return Number(n || 0);
};

const mapExpenseToRequest = ({
  currentExpenses,
  proposedExpenses,
  role
}: {
  currentExpenses: IExpenses;
  proposedExpenses?: IExpenses;
  role?: IRoles;
}): ExpenseRequest => {
  return {
    presentMortgageInsurance: parseNumber(currentExpenses.mortgageInsurance),
    proposedMortgageInsurance: parseNumber(proposedExpenses?.mortgageInsurance),
    presentPropertyTaxes: parseNumber(currentExpenses.propertyTaxes),
    proposedPropertyTaxes: parseNumber(proposedExpenses?.propertyTaxes),
    forRole: role,
    presentRentAmount: parseNumber(currentExpenses.rent),
    proposedRentAmount: parseNumber(proposedExpenses?.rent),
    presentFirstMortgageAmount: parseNumber(currentExpenses.firstMortgage),
    proposedFirstMortgageAmount: parseNumber(proposedExpenses?.firstMortgage),
    presentHoaDuesAmount: parseNumber(currentExpenses.homeownerAssnDues),
    proposedHoaDuesAmount: parseNumber(proposedExpenses?.homeownerAssnDues),
    presentHomeownersInsuranceAmount: parseNumber(
      currentExpenses.homeownersInsurance
    ),
    proposedHomeownersInsuranceAmount: parseNumber(
      proposedExpenses?.homeownersInsurance
    ),
    presentRealEstateTaxesAmount: parseNumber(currentExpenses.realestateTaxes),
    proposedRealEstateTaxesAmount: parseNumber(
      proposedExpenses?.realestateTaxes
    ),
    presentOtherExpensesAmount: parseNumber(currentExpenses.other),
    proposedOtherExpensesAmount: parseNumber(proposedExpenses?.other),
    presentSecondMortgageAmount: 0,
    proposedSecondMortgageAmount: 0,
    presentSupplementalInsuranceAmount: 0,
    proposedSupplementalInsuranceAmount: 0,
    otherExpensesDescription: '',
    propertyTaxesAmountIsUnknown: currentExpenses.idkProperty,
    homeownersInsuranceAmountIsUnknown: currentExpenses.idkHomeownersInsurance
  };
};

const mapExpenseRequestToForm = ({
  expenses,
  type,
  loanUserUuid
}: {
  expenses: IHousingExpense;
  type: 'C' | 'P';
  loanUserUuid?: string;
}): IExpenses => {
  const parseCurrentExpenses = (
    currentProperty: string,
    proposedProperty: string
  ) => {
    if (!expenses) {
      return null;
    }

    return type === 'C'
      ? expenses?.[currentProperty]
      : expenses?.[proposedProperty];
  };

  return {
    loanUserUuid,
    rent: parseCurrentExpenses('presentRentAmount', 'proposedRentAmount'),
    firstMortgage: parseCurrentExpenses(
      'presentFirstMortgageAmount',
      'proposedFirstMortgageAmount'
    ),
    propertyTaxes: parseCurrentExpenses(
      'presentPropertyTaxes',
      'proposedPropertyTaxes'
    ),
    homeownersInsurance: parseCurrentExpenses(
      'presentHomeownersInsuranceAmount',
      'proposedHomeownersInsuranceAmount'
    ),
    realestateTaxes: parseCurrentExpenses(
      'presentRealEstateTaxesAmount',
      'proposedRealEstateTaxesAmount'
    ),
    mortgageInsurance: parseCurrentExpenses(
      'presentMortgageInsurance',
      'proposedMortgageInsurance'
    ),
    homeownerAssnDues: parseCurrentExpenses(
      'presentHoaDuesAmount',
      'proposedHoaDuesAmount'
    ),
    other: parseCurrentExpenses(
      'presentOtherExpensesAmount',
      'proposedOtherExpensesAmount'
    ),
    idkProperty: parseCurrentExpenses(
      'propertyTaxesAmountIsUnknown',
      'propertyTaxesAmountIsUnknown'
    ),
    idkHomeownersInsurance: parseCurrentExpenses(
      'homeownersInsuranceAmountIsUnknown',
      'homeownersInsuranceAmountIsUnknown'
    )
  };
};

export const parseLoanFormToBody = (
  loanForm: ILoanApplicationModel,
  borrower: ILoanUser
): IExpensesBody => {
  const housingExpenses: IExpensesBody['housingExpenses'] = [];

  const borrowerHousingExpenses: IExpensesBody['housingExpenses'][number] = {
    loanUserUuid: borrower.uuid,
    expenses: mapExpenseToRequest({
      currentExpenses: loanForm.currentExpense,
      proposedExpenses: loanForm.proposedExpense,
      role: borrower.role
    })
  };

  housingExpenses.push(borrowerHousingExpenses);

  if (loanForm?.coborrowerExpenses?.length > 0) {
    const coborrowersExpenses = loanForm.coborrowerExpenses.map<
      IExpensesBody['housingExpenses'][number]
    >((expenses) => {
      return {
        loanUserUuid: expenses.loanUserUuid,
        expenses: mapExpenseToRequest({
          currentExpenses: expenses,
          role: IRoles.COBORROWER
        })
      };
    });
    housingExpenses.push(...coborrowersExpenses);
  }
  return {
    shouldShareHousingExpenses: !loanForm.notShareHousingExpenses,
    housingExpenses
  };
};

export const parseLoanStatusToForm = (
  loan: ILoanStatus
): ILoanApplicationModel | null => {
  if (!loan.loanUsers) return null;
  const borrower = getBorrowerFromLoanPending(loan);
  const coborrowers = getCoborrowersFromLoanPending(loan);
  const propertyType = borrower.borrowerInfo.primaryAddressInfo?.type;

  const proposedExpense: IExpenses = mapExpenseRequestToForm({
    expenses: borrower.borrowerInfo?.housingExpenses,
    loanUserUuid: borrower.uuid,
    type: 'P'
  });

  const borrowerCurrentExpense: IExpenses = mapExpenseRequestToForm({
    expenses: borrower.borrowerInfo?.housingExpenses,
    loanUserUuid: borrower.uuid,
    type: 'C'
  });

  const coborrowerCurrentExpense = coborrowers?.map<IExpenses>((coborrower) =>
    mapExpenseRequestToForm({
      expenses: coborrower.borrowerInfo?.housingExpenses,
      loanUserUuid: coborrower.uuid,
      type: 'C'
    })
  );

  return {
    propertyType,
    notShareHousingExpenses: !borrower.borrowerInfo?.shouldShareHousingExpenses,
    currentExpense: borrowerCurrentExpense,
    coborrowerExpenses: coborrowerCurrentExpense,
    proposedExpense: proposedExpense
  };
};

export const getPrimaryAddressStreet = (loan: ILoanStatus): string => {
  const primaryAddress = loan.propertyInfo?.address;
  if (!primaryAddress) return null;
  return primaryAddress.street;
};
