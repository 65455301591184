/* eslint-disable complexity */
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Backdrop, CircularProgress } from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { getLoanStatus } from 'hooks/useLogin';
import { NextPage } from 'next';
import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { GoogleAnalytics } from 'nextjs-google-analytics';
import { SnackbarProvider } from 'notistack';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Provider, useSelector } from 'react-redux';
import { useGetLoansQuery } from 'services/loan';
import { IRoles } from 'services/models/IRoles';
import { hasSession } from 'services/slice/authSlice';
import { store } from 'services/store';
import { getCurrentStep } from 'services/utils';
import { setLocale } from 'yup';
import Chat from 'components/Chat/Chat';
import { snackError } from 'components/Snackbar/snackbarErrorStyle';
import { stepRoutes } from 'constants/step-routes';
import 'styles/globals.css';
import { AppWrapper, useAppContext } from '../context/uuids';

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactElement<any, any>;
  auth: {
    roles: IRoles[];
    redirectLink: string;
  };
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function hasRoleAllowed(roleAllowed: string[], role: IRoles) {
  return roleAllowed?.some((roleCompared) => role === roleCompared);
}

const loginPages = [
  stepRoutes.LANDING.route,
  stepRoutes.LOGIN_BORROWER.route,
  stepRoutes.LOGIN_OFFICER.route
];

function useRedirectWhenForbidden({
  whitelistRoles,
  publicAuth,
  redirectLink
}) {
  const { pathname, push, query } = useRouter();
  const session = useSelector(hasSession);
  const { loanUuid, setLoanUuid, profile } = useAppContext();
  const { data: loans, isSuccess } = useGetLoansQuery(
    { uuid: profile?.uuid },
    {
      skip: !(session && profile?.uuid)
    }
  );
  const { hasLoanStarted, hasCompletedLoan, hasZeroLoans } =
    getLoanStatus(loans);
  const currentLoanUuid = hasLoanStarted?.uuid || profile?.uuid;

  const [hasPermission, setPermission] = useState(false);

  useEffect(() => {
    if (loanUuid === 'LOAN_DONE') return;
    if (currentLoanUuid !== loanUuid && isSuccess) {
      setLoanUuid(currentLoanUuid);
    }
  }, [currentLoanUuid, isSuccess, loanUuid, setLoanUuid]);

  const redirectToRole = useCallback(() => {
    let pipelineUrl = '/pipeline';
    if (query.loan) {
      pipelineUrl = `/pipeline/${query.loan}`;
    }
    switch (profile?.role) {
      case IRoles.OFFICER:
        push(pipelineUrl);
        break;
      case IRoles.ADMIN:
        push(pipelineUrl);
        break;
      case IRoles.ASSISTANT:
        push(pipelineUrl);
        break;
      case IRoles.BORROWER:
        if (hasZeroLoans || hasLoanStarted) {
          if (hasLoanStarted) {
            const step = getCurrentStep(hasLoanStarted, false);
            push(step.route);
          } else {
            push(stepRoutes.SELECT_OFFICER.route);
          }
        } else if (hasCompletedLoan) {
          push(stepRoutes.MY_LOANS.route);
        } else {
          push(stepRoutes.MY_LOANS.route);
        }
        break;
      default:
        break;
    }
  }, [
    hasCompletedLoan,
    hasLoanStarted,
    hasZeroLoans,
    profile?.role,
    push,
    query.loan
  ]);

  const redirectFunction = useCallback(async () => {
    if (session) {
      const currentRoute = pathname;
      const isLoginPath = loginPages.includes(currentRoute);
      const isPublicPath = !isLoginPath && publicAuth;
      const isRoleAllowed = profile?.role
        ? hasRoleAllowed(whitelistRoles, profile?.role)
        : null;
      if (isLoginPath) {
        setPermission(false);
        redirectToRole();
      } else if (isPublicPath || isRoleAllowed) {
        setPermission(true);
      } else {
        setPermission(false);
        redirectToRole();
      }
    } else {
      const isPublicPath = publicAuth;
      if (isPublicPath) {
        setPermission(true);
      } else {
        setPermission(false);
        push(redirectLink);
      }
    }
  }, [
    pathname,
    profile?.role,
    publicAuth,
    push,
    redirectLink,
    redirectToRole,
    session,
    whitelistRoles
  ]);

  useEffect(() => {
    redirectFunction();
  }, [redirectFunction]);

  return hasPermission;
}

export function Auth({ children, whitelistRoles, redirectLink, publicAuth }) {
  const { profile } = useAppContext();
  const shouldRender = useRedirectWhenForbidden({
    publicAuth: publicAuth,
    whitelistRoles,
    redirectLink
  });
  if (shouldRender) {
    return (
      <>
        <Chat role={profile?.role} />
        {children}
      </>
    );
  }

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  setLocale({
    mixed: {
      notType: () => 'Required field'
    }
  });
  const getLayout = useMemo(() => {
    return Component.getLayout ?? ((page) => page);
  }, [Component.getLayout]);
  const component = useMemo(() => {
    return getLayout(<Component {...pageProps} />);
  }, [Component, getLayout, pageProps]);
  return (
    <Provider store={store}>
      <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_API_CLIENT}>
        <GoogleAnalytics trackPageViews />
        <Head>
          <title>Advisors Mortgage Group</title>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          ></meta>
          <meta name="title" content="AMG —  Advisors Mortgage Group" />
          <meta
            name="robots"
            content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
          />
          <meta
            name="description"
            content="Advisors is a multi-state mortgage banker that believes in delivering a seamless, stress-free mortgage experience to all of our customers."
          />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Home - Advisors Mortgage Group" />
          <meta
            property="og:description"
            content="Advisors is a multi-state mortgage banker that believes in delivering a seamless, stress-free mortgage experience to all of our customers."
          />
          <meta property="og:url" content="https://advisorsmortgage.com/" />
          <meta property="og:site_name" content="Advisors Mortgage Group" />
          <meta
            property="article:modified_time"
            content="2022-06-08T16:15:23+00:00"
          />
          <meta name="twitter:card" content="summary_large_image" />
        </Head>
        <SnackbarProvider
          preventDuplicate
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          iconVariant={{
            error: <ErrorOutlineIcon sx={snackError.leftIcon} />
          }}
        >
          <AppWrapper>
            <Auth
              whitelistRoles={Component.auth?.roles}
              redirectLink={Component.auth?.redirectLink}
              publicAuth={!Component.auth}
            >
              {component}
            </Auth>
          </AppWrapper>
        </SnackbarProvider>
      </GoogleOAuthProvider>
    </Provider>
  );
}

export default appWithTranslation(MyApp);
