import {
  IMyAccountFormModel,
  IUpdatePasswordModel
} from 'models/MyAccountFormModel';
import { baseApi } from './baseApi';
import { IProfile } from './models/IProfile';
import { parseMyAccountModelToBody } from './parsers/myAccount';

export const profileApi = baseApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    updateProfile: builder.mutation<
      IProfile,
      { profileValues: IMyAccountFormModel }
    >({
      query: (request) => ({
        url: `/profile`,
        method: 'PUT',
        body: parseMyAccountModelToBody(request.profileValues)
      }),
      invalidatesTags: ['PROFILE']
    }),
    getProfile: builder.query<IProfile, void>({
      query: () => ({
        url: '/profile',
        method: 'GET'
      }),
      providesTags: ['PROFILE']
    }),
    setNewPassword: builder.mutation<void, { body: IUpdatePasswordModel }>({
      query: ({ body }) => ({
        url: `/sessions/password/update`,
        method: 'PUT',
        body
      })
    })
  })
});

export const {
  useUpdateProfileMutation,
  useGetProfileQuery,
  useLazyGetProfileQuery,
  useSetNewPasswordMutation
} = profileApi;
