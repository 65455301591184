import { baseApi } from './baseApi';
import { IAccessToken, IGoogleLogin, ILogin } from './models/ILogin';
import { IUser } from './models/IUser';
import { parseSignUpModelToForm } from './parsers/login';

export const loginApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<IAccessToken, ILogin>({
      query: (login) => ({
        url: '/sessions/login',
        method: 'POST',
        body: login
      }),
      invalidatesTags: ['LOAN', 'OFFICERS', 'SETTINGS', 'PROFILE']
    }),
    googleLogin: builder.mutation<IAccessToken, IGoogleLogin>({
      query: (login) => ({
        url: '/google-authentication/v2',
        method: 'POST',
        body: login
      }),
      invalidatesTags: ['LOAN', 'OFFICERS', 'SETTINGS', 'PROFILE']
    }),
    signUp: builder.mutation<IAccessToken, any>({
      query: (signUpData) => ({
        url: '/sessions/signup',
        method: 'POST',
        body: parseSignUpModelToForm(signUpData)
      }),
      invalidatesTags: ['LOAN', 'OFFICERS', 'SETTINGS', 'PROFILE']
    }),
    signUpWithLink: builder.mutation<IAccessToken, any>({
      query: (signUpData) => ({
        url: '/sessions/signup/officer-link',
        method: 'POST',
        body: parseSignUpModelToForm(signUpData)
      }),
      invalidatesTags: ['LOAN', 'OFFICERS', 'SETTINGS', 'PROFILE']
    }),
    signUpProspect: builder.mutation<IAccessToken, any>({
      query: (signUpData) => ({
        url: '/sessions/signup/prospect',
        method: 'POST',
        body: parseSignUpModelToForm(signUpData)
      }),
      invalidatesTags: ['LOAN', 'OFFICERS', 'SETTINGS', 'PROFILE']
    }),
    logout: builder.mutation<any, void>({
      query: () => ({
        url: '/sessions/logout',
        method: 'DELETE'
      })
    }),
    getLoanOfficerByEmail: builder.query<IUser, string>({
      query: (officer) => ({
        url: `/officers/${officer}`
      })
    })
  }),
  overrideExisting: true
});

export const {
  useGetLoanOfficerByEmailQuery,
  useLoginMutation,
  useLogoutMutation,
  useSignUpMutation,
  useSignUpWithLinkMutation,
  useSignUpProspectMutation,
  useGoogleLoginMutation
} = loginApi;
