import { getPercentage } from 'components/TextInput/Percentage';
import { ILoanAmountFormModel } from 'models/LoanAmountFormModel';
import { ILoanStatus } from '../models/ILoanStatus';
import { PurposeType } from './purpose';

export function parseToForm(loanStatus: ILoanStatus): ILoanAmountFormModel {
  const loanTerm = loanStatus.loanTerm;
  const downPaymentAmount = Number(
    loanStatus?.purchaseComplementaryInfo?.downPaymentAmount ?? 0
  );
  const purchasePrice = Number(
    loanStatus?.purchaseComplementaryInfo?.purchasePrice ?? 0
  );

  return {
    purposeType: loanStatus.purposeType,
    purchasePrice: purchasePrice,
    downPayment: downPaymentAmount,
    downPaymentPercentage: Number(
      getPercentage(downPaymentAmount, purchasePrice).toFixed(2)
    ),
    estimatedPropertyValue: Number(
      loanStatus?.refinanceComplementaryInfo?.estimatedPropertyValue ?? 0
    ),
    currentLoanBalance: Number(
      loanStatus?.refinanceComplementaryInfo?.currentLoanBalance ?? 0
    ),
    cashOutAmount: Number(
      loanStatus?.refinanceComplementaryInfo?.cashOutAmount ?? 0
    ),
    loanTerm
  };
}

export function parseFormModelToLoanAmount(request): any {
  const purchaseType = request.purposeType;
  const purchaseInfo =
    purchaseType === 'purchase'
      ? {
          purposeType: purchaseType,
          purchaseComplementaryInfo: {
            purchasePrice: request.purchasePrice,
            downPaymentAmount: request.downPayment
          },
          loanTerm: request.loanTerm
        }
      : {
          purposeType: purchaseType,
          refinanceComplementaryInfo: {
            estimatedPropertyValue: request.estimatedPropertyValue,
            currentLoanBalance: request.currentLoanBalance,
            cashOutAmount: request.cashOutAmount
          },
          loanTerm: request.loanTerm
        };

  return purchaseInfo;
}

export function calculateLoanAmount(loan): number {
  if (!loan.purposeType) return 0;

  if (
    loan.purposeType === PurposeType.Purchase &&
    loan.purchaseComplementaryInfo
  ) {
    //prettier-ignore
    const { purchasePrice, downPaymentAmount } = loan.purchaseComplementaryInfo;
    return purchasePrice - downPaymentAmount;
  }

  if (
    loan.purposeType === PurposeType.Refinance &&
    loan.refinanceComplementaryInfo
  ) {
    //prettier-ignore
    const { estimatedPropertyValue, cashOutAmount, currentLoanBalance } = loan.refinanceComplementaryInfo;
    return Math.max(estimatedPropertyValue, currentLoanBalance) - cashOutAmount;
  }

  return 0;
}
