import { ISignUp } from 'services/models/ILogin';
import { SignUpFormModel } from 'models/CreateAccountModel';

export function parseSignUpModelToForm(
  signUpFormModel: SignUpFormModel
): ISignUp {
  const {
    first_name,
    last_name,
    phone_primary,
    email,
    state,
    suffix,
    referal_source,
    referal_info,
    choose_password,
    terms,
    officerEmail,
    httpReferrer,
    fromCalculator,
    prospectUUID,
    fromQR
  } = signUpFormModel;
  return {
    suffix: suffix || '-',
    firstName: first_name,
    middleName: '',
    lastName: last_name,
    primaryPhone: phone_primary,
    email: email,
    propertyStateApply: state,
    password: choose_password,
    referralSource: referal_source,
    referralSourceInfo: referal_info,
    acceptedTerms: terms,
    fromCalculator,
    officerEmail,
    httpReferrer,
    prospectUUID,
    fromQR
  };
}
