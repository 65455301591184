import { baseApi } from './baseApi';
import { IBorrowerInformation } from './models/IBorrowerInformation';
import { ILoanAmount } from './models/ILoanAmount';
import { ILoanStatus } from './models/ILoanStatus';
import { IPricing } from './models/IPricing';
import { parseFormModelToBorrowerInfo } from './parsers/borrowerInformation';
import { parseFormModelToCoBorrowerInfo } from './parsers/coBorrowerInformation';
import { parseFormModelToLoanAmount } from './parsers/loanAmount';

export const loanApi = baseApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    createLoan: builder.mutation<
      ILoanStatus,
      { officerUUID: string; httpReferrer?: string }
    >({
      query: (body) => ({
        url: '/loans',
        method: 'POST',
        body
      }),
      invalidatesTags: ['LOAN', 'LOANS']
    }),
    updateLoanOfficer: builder.mutation<
      ILoanStatus,
      { loanID: string; officerID?: string; skip?: boolean }
    >({
      query: (request) => ({
        url: `/loans/${request.loanID}/officer`,
        method: 'PUT',
        body: {
          uuid: !request.skip ? request.officerID : null,
          skip: request.skip
        }
      }),
      invalidatesTags: (result) => (result ? ['LOAN'] : [])
    }),
    getPendingLoan: builder.query<ILoanStatus, { uuid: string }>({
      query: (request) => ({
        url: `/loans/pending/${request.uuid}`
      }),
      providesTags: ['LOAN']
    }),
    getLoans: builder.query<ILoanStatus[], { uuid: string }>({
      query: (request) => ({
        url: `/loans/user/${request.uuid}`
      }),
      providesTags: ['LOANS']
    }),
    updateLoanAmount: builder.mutation<ILoanStatus, ILoanAmount>({
      query: (request) => ({
        url: `/loans/${request.loanID}/loan-amounts`,
        method: 'PUT',
        body: parseFormModelToLoanAmount(request)
      }),
      invalidatesTags: (result) => (result ? ['LOAN'] : [])
    }),
    updateBorrowerInformation: builder.mutation<
      ILoanStatus,
      IBorrowerInformation
    >({
      query: (request) => ({
        url: `/loans/${request.loanID}/borrower-info/update`,
        method: 'PUT',
        body: parseFormModelToBorrowerInfo(request)
      }),
      invalidatesTags: (result) => (result ? ['LOAN'] : [])
    }),
    updateCoBorrowerInformation: builder.mutation<
      ILoanStatus,
      IBorrowerInformation
    >({
      query: (request) => ({
        url: `/loans/${request.loanID}/co-borrower`,
        method: 'PUT',
        body: parseFormModelToCoBorrowerInfo(request)
      })
    }),
    pricing: builder.query<
      IPricing[],
      {
        loanID: string;
      }
    >({
      query: (request) => ({
        url: `/pricing/${request.loanID}`,
        method: 'GET'
      })
    }),
    setPricing: builder.mutation<
      ILoanStatus,
      {
        loanID: string;
      }
    >({
      query: ({ loanID, ...request }) => ({
        url: `/loans/${loanID}/set-pricing`,
        method: 'PUT',
        body: {
          pricingOption: request
        }
      }),
      invalidatesTags: (result) => (result ? ['LOAN'] : [])
    }),
    getLoanOverview: builder.query<ILoanStatus, { loanID: string }>({
      query: ({ loanID }) => ({
        url: `loans/${loanID}/overview`
      }),
      providesTags: ['LOAN']
    }),
    submitLoan: builder.mutation<
      ILoanStatus,
      ILoanStatus & { language: string }
    >({
      query: (request) => ({
        url: `/loans/${request.uuid}/submit`,
        method: 'POST',
        body: request
      }),
      invalidatesTags: []
    })
  })
});

export const {
  useGetPendingLoanQuery,
  useLazyGetPendingLoanQuery,
  useGetLoansQuery,
  useLazyGetLoansQuery,
  useGetLoanOverviewQuery,
  useCreateLoanMutation,
  useUpdateLoanOfficerMutation,
  useUpdateLoanAmountMutation,
  useUpdateBorrowerInformationMutation,
  useUpdateCoBorrowerInformationMutation,
  useSubmitLoanMutation,
  usePricingQuery,
  useLazyPricingQuery,
  useSetPricingMutation
} = loanApi;
