import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { baseApi } from 'services/baseApi';
import { IAccessToken } from 'services/models/ILogin';
import { RootState } from 'services/store';
import { getModel, saveModel } from 'utils/modelUtils';

interface AuthState {
  isAuthenticated: boolean;
  isRefreshing: boolean;
  accessToken: string | null;
  refreshToken: string | null;
}

export const setCredentials = createAsyncThunk(
  'credentials',
  async ({ accessToken, refreshToken }: IAccessToken, thunk) => {
    if (accessToken) {
      await thunk.dispatch(baseApi.util.resetApiState());
    }
    saveModel(accessToken, 'accessToken');
    saveModel(refreshToken, 'refreshToken');
    return accessToken;
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isAuthenticated: false,
    isRefreshing: false,
    user: null,
    accessToken: getModel('accessToken', null),
    refreshToken: getModel('refreshToken', null)
  } as AuthState,
  reducers: {
    setAuthState: (state, action) => {
      const { isAuthenticated, accessToken, refreshToken } = action.payload;
      state.isAuthenticated = isAuthenticated;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
    },
    setRefreshing: (state, action) => {
      const { isRefreshing } = action.payload;
      state.isRefreshing = isRefreshing;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(setCredentials.fulfilled, (state, action) => {
      state.accessToken = action.payload;
    });
  }
});

export const { setAuthState, setRefreshing } = authSlice.actions;

export const authReducer = authSlice.reducer;

export const hasSession = (state: RootState): string => state.auth.accessToken;
