import { createContext, useContext, useMemo, useState } from 'react';
import { IProfile } from 'services/models/IProfile';
import { useGetProfileQuery } from 'services/profile';
import { hasSession } from 'services/slice/authSlice';
import { useSelector } from 'services/store';

export const AppContext = createContext<{
  profile: IProfile;
  profileUuid: string;
  loanUuid: string;
  setLoanUuid: (x: string) => void;
  isPlaidLoading: boolean;
  setIsPlaidLoading: (x: boolean) => void;
}>({
  profile: null,
  profileUuid: null,
  loanUuid: null,
  setLoanUuid: () => ({}),
  isPlaidLoading: false,
  setIsPlaidLoading: () => ({})
});

export function AppWrapper({ children }) {
  const session = useSelector(hasSession);
  const [loanUuid, setLoanUuid] = useState(null);
  const { data: profile } = useGetProfileQuery(undefined, {
    skip: !session
  });
  const [isPlaidLoading, setIsPlaidLoading] = useState(false);
  const value = useMemo(
    () => ({
      profile,
      profileUuid: profile?.uuid,
      loanUuid,
      setLoanUuid,
      isPlaidLoading,
      setIsPlaidLoading
    }),
    [isPlaidLoading, loanUuid, profile]
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export function useAppContext() {
  return useContext(AppContext);
}
