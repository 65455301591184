import { useAppContext } from 'context/uuids';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { menu, menuWithPricing } from 'constants/menu';
import { stepRoutes } from 'constants/step-routes';
import { useGetPendingLoanQuery } from './loan';
import { ILoanStatus, ILoanUser } from './models/ILoanStatus';
import { IRoles } from './models/IRoles';
import { IStepRoute } from './models/IStepRoute';
import { hasSession } from './slice/authSlice';
import { useSelector } from './store';

export function getCurrentStep(
  applicationProgress: ILoanStatus,
  hasPricing: boolean
): null | {
  label: string;
  id: string;
  route?: string;
  subMenu?: any;
} {
  const localMenu = hasPricing ? menuWithPricing : menu;
  let currentStep = null;
  const doneSteps = parseDoneSteps(applicationProgress, hasPricing);
  localMenu.forEach((menuItem, menuIndex) => {
    menuItem.subMenu?.forEach((subMenuItem, subMenuIndex) => {
      if (doneSteps[subMenuItem.id]) {
        if (menuItem.subMenu[subMenuIndex + 1]) {
          currentStep = menuItem.subMenu[subMenuIndex + 1];
        } else if (localMenu[menuIndex + 1]) {
          if (localMenu[menuIndex + 1].subMenu) {
            currentStep = localMenu[menuIndex + 1].subMenu[0];
          } else {
            currentStep = localMenu[menuIndex + 1];
          }
        }
      }
    });
    if (menuItem.route && doneSteps[menuItem.id] && menuItem[menuIndex + 1]) {
      currentStep = menuItem[menuIndex + 1];
    }
  });
  if (!currentStep) {
    return stepRoutes.SELECT_OFFICER;
  }
  return currentStep;
}

function getOrderFromStep(lmenu, step) {
  let order;
  lmenu.forEach((menuItem, index) => {
    menuItem.subMenu?.forEach((subMenuItem, subIndex) => {
      if (subMenuItem.id === step) {
        order = `${100 * (index + 1) + (subIndex + 1)}`;
      }
    });
    if (menuItem.id === step) {
      order = `${100 * (index + 1)}`;
    }
  });
  return order;
}

export function canGoToRoute({
  applicationProgress,
  hasPricing,
  route
}: {
  applicationProgress: ILoanStatus;
  hasPricing: boolean;
  route: string;
}) {
  const localMenu = hasPricing ? menuWithPricing : menu;
  const step = findStep(localMenu, route);
  if (step) {
    const stepOrder = getOrderFromStep(localMenu, step.id);
    const currentStep = getCurrentStep(applicationProgress, hasPricing);
    const currentStepOrder = getOrderFromStep(localMenu, currentStep.id);
    return Number(stepOrder) <= Number(currentStepOrder);
  }
  return false;
}

export function findStep(localMenu, route) {
  const step = localMenu.find((menuItem) => {
    if (menuItem.route === route) {
      return true;
    } else if (menuItem.subMenu) {
      return menuItem.subMenu.find((subMenuItem) => {
        return subMenuItem.route === route;
      });
    } else {
      return false;
    }
  });
  if (step?.route === route) {
    return step;
  } else if (step?.subMenu) {
    return step.subMenu.find((subMenuItem) => {
      return subMenuItem.route === route;
    });
  }
}

export function useLoanPending() {
  const { loanUuid } = useAppContext();
  const { isReady } = useRouter();
  const session = useSelector(hasSession);
  const paramLoanUuid = useMemo(() => {
    return { uuid: loanUuid };
  }, [loanUuid]);
  const params = useMemo(() => {
    return paramLoanUuid;
  }, [paramLoanUuid]);
  return useGetPendingLoanQuery(params, {
    skip: !(isReady && session && params.uuid !== 'LOAN_DONE' && params.uuid)
  });
}

export const parseDoneSteps: (
  loanStatus: ILoanStatus,
  hasPricing: boolean
) => {
  [key: string]: boolean;
} = (loanStatus, hasPricing) => {
  const result = {};
  const localMenu = hasPricing ? menuWithPricing : menu;
  localMenu.forEach((menuItem) => {
    menuItem.subMenu?.forEach((subMenuItem) => {
      if (checkStepProgress(stepRoutes[subMenuItem.id], loanStatus)) {
        result[subMenuItem.id] = true;
      }
    });
    if (checkStepProgress(stepRoutes[menuItem.id], loanStatus)) {
      result[menuItem.id] = true;
    }
  });
  return result;
};

export const checkStepProgress = (
  stepRoute: IStepRoute,
  loanStatus: ILoanStatus
): boolean => {
  if (!loanStatus) return false;
  const applicationFormProgress = loanStatus.loanApplicationFormProgress;
  if (stepRoute.moduleKey) {
    return applicationFormProgress
      .filter((step) => {
        return step.module === stepRoute.moduleKey;
      })
      .every((step) => step.done === true);
  } else {
    const appModule = applicationFormProgress.find((step) => {
      return step.section === stepRoute.sessionFormkey;
    });
    if (appModule) return appModule.done;
    return false;
  }
};

export const findBorrowerUser = (loan: ILoanStatus): ILoanUser | null => {
  return loan.loanUsers.find((loanUser) => {
    return loanUser.role === IRoles.BORROWER;
  });
};

export const validateSameAddress = (formik, addressKey: string): any => {
  return formik.values.borrowerAddress?.isMailingAndPrimarySame
    ? formik.values.borrowerAddress?.primaryAddressInfo?.address?.[addressKey]
    : formik.values.borrowerAddress?.mailingAddress[addressKey];
};
