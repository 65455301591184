import { format, isValid, parse } from 'date-fns';
import {
  FormikErrors,
  FormikProps,
  FormikState,
  FormikTouched,
  FormikValues
} from 'formik';
import { createContext, useContext, useState } from 'react';
import { IAddress } from 'services/models/IAddress';
import { IDocumentLoanFile } from 'services/models/IDocuments';
import { IHousingExpense } from 'services/models/IExpenses';
import {
  IBorrowerInfo,
  ILoanStatus,
  ILoanUser
} from 'services/models/ILoanStatus';
import { IRoles } from 'services/models/IRoles';
import { Url } from 'url';
import {
  adminOptions,
  userDropdownOptions,
  userOptions,
  userOptionsEnum
} from 'constants/menu';
import { states } from 'constants/states';
import { ILoanUserAssets } from 'models/AssetsFormModel';
import { ILoanApplicationModel } from 'models/LoanApplicationModel';
import { IUser } from '../services/models/IUser';
import { findValueParams, SumTotalIncomeParams, ValidationType } from './types';

export function parseJwt(token) {
  if (!token) {
    return;
  }
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
}

export function sum(values: Array<string | number>): string {
  const total = values.reduce<number>((acc, nextValue) => {
    const value =
      typeof nextValue === 'number' ? nextValue : parseFloat(nextValue) || 0;
    return acc + value;
  }, 0);
  return total.toFixed(2);
}

export function sumTotalIncome({
  formik,
  values,
  inputName
}: SumTotalIncomeParams): void {
  const props = values;
  const total = sum(props);
  formik.setFieldValue(inputName, total);
}

export function getNestedValue(
  key: string,
  obj:
    | FormikState<any>
    | FormikProps<any>
    | FormikTouched<any>
    | FormikErrors<any>
    | ILoanApplicationModel
    | any
): any | null {
  key = key?.replace(/\[(\w+)\]/g, '.$1');
  key = key?.replace(/^\./, '');
  return key?.split('.').reduce((prev, curr) => {
    return prev ? (prev = prev[curr]) : null;
  }, obj);
}

export function propertyCheckbox(value: boolean): any {
  return value ? 'yes' : 'no';
}

export function formatAddress(
  address: string,
  city: string,
  state: string,
  zipcode: string
): string {
  if (!address && !city) return null;

  return [address, city, state, zipcode].filter((a) => a).join(' ');
}

export function formatAddressLoan(address: IAddress): string {
  if (!address) return null;

  const { street, city, state, zip } = address;
  const cityWithComma = city && `${city},`;
  return [street, cityWithComma, state, zip].filter((a) => a).join(' ');
}

export function findLabel({
  options,
  value,
  key = 'value',
  label = 'label',
  defaultLabel,
  t
}: findValueParams): string {
  const option = options?.find(
    (option) => option[key].toLowerCase() === value?.toLowerCase()
  );
  return t(option?.[label] || defaultLabel);
}

export function getFormikValidation(
  formik: FormikProps<FormikValues | any>,
  key: string,
  validationType: ValidationType,
  skipTouched?: boolean
): any {
  const hasErrorFlag = validationType === 'errors';
  const nestedV = getNestedValue(key, formik.errors);
  const hasNestedValues = skipTouched
    ? nestedV
    : getNestedValue(key, formik.touched) && nestedV;

  return hasErrorFlag ? Boolean(hasNestedValues) : hasNestedValues;
}

export function formatDate(
  value: string,
  type?: string,
  formatType?: string
): string | null {
  if (!value) return null;
  let newValue: any = value;
  if (formatType) {
    newValue = parse(newValue, formatType, new Date());
  }
  const date = new Date(newValue);
  const isValidDate = isValid(date);
  const convertType = type === 'only-date' ? 'MM/dd/yyyy' : 'LLL dd, u';
  return isValidDate ? format(date, convertType) : value;
}

export function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function sumValues(values: number[]): number {
  return values.reduce((a, b) => a + b, 0);
}

export function generateArrayFrom(
  number: number,
  appendZero?: boolean,
  addPlus?: boolean
): Array<{ label: string; value: string }> {
  const numberArray = Array.from(
    Array(appendZero ? number + 1 : number).keys()
  );

  return numberArray.map((_, i) => {
    const isLast = i === numberArray.length - 1;
    const value = appendZero ? i.toString() : String(i + 1);

    return {
      label: (isLast && addPlus ? '+' : '') + value,
      value
    };
  });
}

export function getUserWithRole(
  loanPending: ILoanStatus,
  role: IRoles
): ILoanUser | undefined {
  return loanPending?.loanUsers?.find((loanUser) => loanUser?.role === role);
}

export function getUsersWithRole(
  loanPending: ILoanStatus,
  role: IRoles
): Array<ILoanUser> | undefined {
  return loanPending?.loanUsers?.filter((loanUser) => loanUser?.role === role);
}

export function getBorrowerFromLoanPending(
  loanPending: ILoanStatus
): ILoanUser {
  return loanPending?.loanUsers?.find(
    (loanUser) => loanUser?.role === IRoles.BORROWER
  );
}

export function getOfficerFromLoanPending(loanPending: ILoanStatus): ILoanUser {
  return loanPending?.loanUsers?.find(
    (loanUser) => loanUser?.role === IRoles.OFFICER
  );
}

export function getCoBorrowerFormLoanPending(loanPending: ILoanStatus): any {
  return loanPending?.loanUsers?.filter?.(
    (user) => user?.role === IRoles.COBORROWER
  );
}

export function getCoborrowerFromLoanPending(
  loanPending: ILoanStatus
): ILoanUser | undefined {
  return getUserWithRole(loanPending, IRoles.COBORROWER);
}

export function getCoborrowersFromLoanPending(
  loanPending: ILoanStatus
): Array<ILoanUser> {
  return getUsersWithRole(loanPending, IRoles.COBORROWER) || [];
}

export function getFullName(loan: IBorrowerInfo | IUser): string {
  return `${loan?.firstName || ''} ${loan?.middleName || ''} ${
    loan?.lastName || ''
  }`;
}

export function formatUriPhoneNumber(phoneNumberString: string): string {
  if (!phoneNumberString) return phoneNumberString;
  const cleaned = phoneNumberString.replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return ['tel:1', '-', match[2], '-', match[3], '-', match[4]].join('');
  }
  return '';
}

export function formatPhoneNumber(phoneNumberString: string): string {
  if (!phoneNumberString) return phoneNumberString;
  const cleaned = phoneNumberString.replace(/\D/g, '');
  if (phoneNumberString.startsWith('+1')) {
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{3,})$/);
    if (match) {
      return ['(', match[2], ') ', match[3], '-', match[4]].join('');
    }
  } else {
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{3,})$/);
    if (match) {
      return ['(', match[1], ') ', match[2], '-', match[3]].join('');
    }
  }
}

export function generateArrayOfNumbers(
  min: number,
  max: number
): Array<{ values: number; label: number }> {
  const arrayOfNumbers = [];
  for (let i = min; i <= max; i++) {
    const number = {
      value: String(i),
      label: String(i)
    };
    arrayOfNumbers.push(number);
  }

  return arrayOfNumbers;
}

export function capitalizeFirstLetter(str: string): string {
  const firstLetter = str?.[0].toUpperCase();
  const otherLetter = str?.slice(1).toLowerCase();
  return firstLetter?.concat(otherLetter);
}

export function getBorrowerName(borrower: IBorrowerInfo): string {
  return `${parseString(borrower?.firstName)} ${parseString(
    borrower?.middleName
  )} ${parseString(borrower?.lastName)}`;
}

export function parseString(text: string): string {
  return text || '';
}

export function transformLoanTerm(loanTerm: string): string {
  if (!loanTerm) return null;

  const regex = /-/g;

  loanTerm = loanTerm.replace(regex, ' ').replace('yr', 'years');
  return loanTerm;
}

export function getBorrowerFullName(borrowerInfo: ILoanUser): string {
  const firstName = borrowerInfo?.borrowerInfo?.firstName || '';
  const middleName = borrowerInfo?.borrowerInfo?.middleName || '';
  const lastName = borrowerInfo?.borrowerInfo?.lastName || '';
  return firstName.concat(' ').concat(middleName).concat(' ').concat(lastName);
}

export function getCoBorrowerFullName(coBorrowerInfo: ILoanUser): string {
  const info = coBorrowerInfo?.borrowerInfo || coBorrowerInfo?.user;

  const firstName = info?.firstName || '';
  const middleName = info?.middleName || '';
  const lastName = info?.lastName || '';
  return firstName.concat(' ').concat(middleName).concat(' ').concat(lastName);
}

export function stringifyAddress(address: IAddress): string {
  if (!address) return null;
  return `${address.complement || ''} ${address.street} ${address.city}, ${
    address.state
  } ${address.zip}`;
}

export function strigifyBorrowerAddress(address: IAddress): string {
  if (!address) return null;
  return `${address.complement || ''} ${address.street} - ${address.city}- ${
    address.state
  }, ${address.zip}`;
}

export function getLoanTerm(loanTerm: string): any {
  if (!loanTerm) return null;

  const loanTermSplit = loanTerm.split('-');

  return {
    years: loanTermSplit[0],
    type: loanTermSplit[2].includes('arm') ? 'ARM' : 'Fixed'
  };
}

export function getBorrowerFullNameFromLoanPending(
  loanPending: ILoanStatus
): string {
  const borrower = loanPending?.loanUsers?.find(
    ({ role }) => role === IRoles.BORROWER
  );
  return borrower.borrowerInfo?.firstName
    ? borrower.borrowerInfo?.firstName.concat(
        ' ',
        borrower.borrowerInfo?.lastName
      )
    : borrower.user?.firstName?.concat(' ', borrower.user?.lastName);
}

export function getLoanUserName(user: ILoanUser): string {
  return `${user?.borrowerInfo?.firstName} ${user?.borrowerInfo?.lastName}`;
}

export const calculatePresentPayments = (expenses: IHousingExpense): number => {
  if (expenses) {
    return Object.keys(expenses).reduce((sum, key) => {
      if (key?.startsWith('present') && expenses[key] > 0) {
        return sum + expenses[key];
      }
      return sum;
    }, 0);
  }

  return 0;
};

export function parseDate(date: string): string {
  const dateParts = date.split('-');
  const newDate = new Date(date);
  let dateWithTime;
  if (dateParts[2].includes('T')) {
    dateWithTime = `${
      newDate.getMonth() + 1
    }/${newDate.getDate()}/${newDate.getFullYear()}`;
    return dateWithTime;
  }
  return String(dateParts[2]).concat(
    '/',
    String(dateParts[1]),
    '/',
    dateParts[0]
  );
}

export function parseYesNoAnswer(answer: boolean): string {
  return answer ? 'yes' : 'no';
}

export function truncateText(str: string, num: number): string {
  if (str?.length > num) {
    return str.slice(0, num) + '...';
  } else {
    return str;
  }
}

export function parseDocumentsPerPage(
  documents: any,
  currentPage: number,
  documentsPerPage: number
): any {
  const startFrom = currentPage * documentsPerPage;
  const endOn = startFrom + documentsPerPage;
  const currentDocuments = documents.slice(startFrom, endOn);
  return currentDocuments;
}

export function parseUploaderFullName(
  files: Array<IDocumentLoanFile>,
  loanStatus: ILoanStatus
): string {
  const userData = files.map((file) => {
    return loanStatus?.loanUsers.find((userInfo) => {
      return userInfo?.user?.uuid === file?.uploaderUUID;
    });
  });
  return userData[0]?.user?.firstName.concat(' ', userData[0]?.user?.lastName);
}

/**
 * Parse state code into state label from
 * @param abbreviationOrState - is the key of the state e.g, Ak
 * @returns {string} - is the label of the state e.g, Arkansas
 */
export function getStateName(abbreviationOrState: string) {
  return (
    states.find((s) => s.abbreviation === abbreviationOrState?.toUpperCase?.())
      ?.name ?? abbreviationOrState
  );
}

export function handleUserRoutes(id: any): Url | any {
  const isIncluded = userDropdownOptions.includes(id);
  let route;
  if (isIncluded) {
    route = adminOptions.find((option) => id === option.id && option.link);
    if (
      route?.id === userOptionsEnum.MY_ACCOUNT ||
      route?.id === userOptionsEnum.LOG_OUT
    ) {
      route = userOptions.find(
        (option) =>
          id === option.id && id !== userOptionsEnum.LOG_OUT && option.link
      );
    }
  }

  return route;
}

export function getName(user: ILoanUser): string {
  return user?.borrowerInfo?.firstName || user?.user?.firstName;
}

export function getOfficerNameFromPipelineData(data: ILoanStatus): string {
  const loanOfficer = data?.loanUsers.find((loanUser) => {
    return loanUser.role === IRoles.OFFICER;
  });
  if (loanOfficer) {
    return getLoanOfficerName(loanOfficer);
  }
  return null;
}

export function getErrorCode(errorCode) {
  return String(errorCode ?? '').toLowerCase();
}

export const getError = (errorResponse, defaultMessage) => {
  const codeError = String(
    errorResponse?.error?.data?.errorCode ?? ''
  ).toLowerCase();
  const message = errorResponse?.error?.data?.message ?? defaultMessage;
  return [`errors.${codeError}`, message];
};

export function getLoanOfficerName(loanUser: ILoanUser): string {
  if (loanUser.role === IRoles.OFFICER) {
    return loanUser.user.firstName.concat(' ', loanUser.user.lastName);
  }
  return null;
}

export function debounce(func, wait, immediate = false): typeof func {
  let timeout;
  return function () {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this;
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) func.apply(context, args);
  };
}

export function arrayFromEnum(anyEnum) {
  const keys = Object.entries(anyEnum).map(([_value, label]) => ({
    value: label,
    label
  }));
  return keys;
}

const SelectedUser = createContext<{
  user: ILoanUser | ILoanUserAssets[number];
  submit?: () => Promise<any>;
  isLoading: boolean;
  setIsLoading: (b: boolean) => void;
}>(null);
export const useSelectedUser = () => useContext(SelectedUser);

export function UserProviderHelper({ children, user, submit = undefined }) {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <SelectedUser.Provider value={{ user, submit, isLoading, setIsLoading }}>
      {children}
    </SelectedUser.Provider>
  );
}

export async function pollCondition(conditionFn, interval, timeout) {
  const startTime = Date.now();

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    const checkCondition = async () => {
      if (await conditionFn()) {
        resolve(true);
      } else if (Date.now() - startTime < timeout) {
        setTimeout(checkCondition, interval);
      } else {
        resolve(false);
      }
    };
    checkCondition();
  });
}
