/* eslint-disable complexity */
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import cookieCutter from 'cookie-cutter';
import fileDownload from 'js-file-download';
import router from 'next/router';
import { stepRoutes } from 'constants/step-routes';
import { getModel } from 'utils/modelUtils';
import { baseApi } from './baseApi';
import { setAuthState, setCredentials, setRefreshing } from './slice/authSlice';
import { RootState } from './store';

declare module 'axios' {
  export interface AxiosRequestConfig {
    store?: BaseQueryApi;
  }
}

export const baseURL = process.env.NEXT_PUBLIC_BACKEND_ENDPOINT;

export const instance = axios.create({
  baseURL
});
const redirectLogin = () => {
  const role = cookieCutter.get('currentRole');

  if (role === 'officer') {
    router.push(stepRoutes.LOGIN_OFFICER.route);
  } else {
    router.push(stepRoutes.LOGIN_BORROWER.route);
  }
};

const getRefreshToken = async () => {
  const refreshToken = getModel('refreshToken', '');
  const accessToken = getModel('accessToken', '');
  const response = await instance.put(`${baseURL}/sessions/refresh`, {
    refreshToken,
    accessToken
  });
  return {
    accessToken: response.data?.accessToken ?? accessToken,
    refreshToken
  };
};

instance.interceptors.response.use(
  (data) => data,
  async (error) => {
    const {
      config: { store, clone, headers, url }
    } = error;
    const { isRefreshing } = store && (store.getState() as RootState).auth;
    const { dispatch } = store;
    const includesUrl = ['login', 'signup', '/sessions/refresh'].some((path) =>
      url.includes(path)
    );
    if (
      !includesUrl &&
      error.response?.status === 401 &&
      !clone &&
      !isRefreshing &&
      getModel('refreshToken', '')
    ) {
      dispatch(setRefreshing({ isRefreshing: true }));

      try {
        const { accessToken, refreshToken } = await getRefreshToken();

        dispatch(
          setAuthState({ accessToken, refreshToken, isAuthenticated: true })
        );
        dispatch(setCredentials({ accessToken, refreshToken }));
        dispatch(setRefreshing({ isRefreshing: false }));

        headers['Authorization'] = `Bearer ${accessToken}`;
        error.config.clone = true;

        return instance.request(error.config);
      } catch (err) {
        dispatch(
          setCredentials({ accessToken: '', refreshToken: '' })
        ).unwrap();
        dispatch(baseApi.util.resetApiState());
        dispatch(setRefreshing({ isRefreshing: false }));
        redirectLogin();
        throw err;
      }
    }
    throw error;
  }
);

const axiosBaseQuery =
  (): BaseQueryFn<
    {
      url: string;
      method?: AxiosRequestConfig['method'];
      body?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
      headers?: AxiosRequestConfig['headers'];
      transformResponse?: (response: any) => any;
      onUploadProgress?: (progressEvent: ProgressEvent) => void;
      abortController?: AbortController;
    },
    unknown,
    unknown
  > =>
  async (
    {
      url,
      method = 'GET',
      body,
      params,
      headers,
      transformResponse,
      onUploadProgress,
      abortController
    },
    baseQueryApi
  ) => {
    const { accessToken } = (baseQueryApi.getState() as RootState).auth;
    try {
      const result = await instance.request({
        baseURL,
        timeout: 2 * 60 * 1000,
        url: url,
        method,
        data: body,
        store: baseQueryApi,
        params: {
          ...params,
          // Let me explain the following "seed" property:
          // We still don't know why, but every GET request in production was being cached and returned to the user without hitting the server
          // so we were experiencing some update issues (i.e. the user would update some properties on the frontend and not see any effect ) and,
          // worse, sometimes the route returned data from another user.
          // Then we found that production was caching the indexing of the response by route,
          // so we change the route every time we make a request using this "seed" property in the query string.
          // Maybe one day we'll change this, but for now, DO NOT REMOVE THE SEED!
          seed: Math.random()
        },
        signal: abortController?.signal ?? baseQueryApi.signal,
        headers: accessToken
          ? {
              Authorization: 'Bearer ' + accessToken,
              'Content-Type': 'application/json',
              'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate',
              Pragma: 'no-cache',
              Expires: '0',
              ...headers
            }
          : {
              'Content-Type': 'application/json',
              'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate',
              Pragma: 'no-cache',
              Expires: '0',
              ...headers
            },
        onUploadProgress
      });
      if (!transformResponse) return { data: result.data };
      return { data: transformResponse(result.data) };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      if (!transformResponse) {
        return {
          error: { status: err.response?.status, data: err.response?.data }
        };
      }
      return {
        error:
          transformResponse({
            status: err.response?.status,
            data: err.response?.data
          }) ?? err
      };
    }
  };

export const baseQuery = axiosBaseQuery();

export function downloadMISMO(
  loanNumber: string,
  uuid: string,
  token: string
): void {
  instance
    .get(`/loans/${uuid}/byte/get-xml`, {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then((res) => {
      fileDownload(res.data, `MISMO-${loanNumber}.xml`);
    });
}
