export function saveModel(form: any, key: string): void {
  if (typeof window !== 'undefined') {
    window?.sessionStorage.setItem(key, JSON.stringify(form));
  }
}

export function modelExists(key: string): boolean {
  if (typeof window === 'undefined') {
    return false;
  }
  return window.sessionStorage.getItem(key) !== null;
}

export function getModel(key: string, defaultValues: any): any {
  if (!modelExists(key)) {
    return defaultValues;
  }
  const valueSessionStorage = window?.sessionStorage.getItem(key);
  if (valueSessionStorage) {
    try {
      return JSON.parse(valueSessionStorage);
    } catch (error) {
      return defaultValues;
    }
  }
  return defaultValues;
}
