import { stepRoutes } from './step-routes';

export type IOptions = Array<{
  label: string;
  id: userOptionsEnum | adminOptionsEnum | languageOptionsEnum;
  subtitle?: boolean;
  link?: string;
}>;

export const menu = [
  {
    label: stepRoutes.START.label,
    id: stepRoutes.START.id,
    subMenu: [
      {
        label: stepRoutes.SELECT_OFFICER.label,
        id: stepRoutes.SELECT_OFFICER.id,
        route: stepRoutes.SELECT_OFFICER.route
      },
      {
        label: stepRoutes.ABOUT_THE_LOAN.label,
        id: stepRoutes.ABOUT_THE_LOAN.id,
        route: stepRoutes.ABOUT_THE_LOAN.route
      }
    ]
  },
  {
    label: stepRoutes.INFORMATION.label,
    id: stepRoutes.INFORMATION.id,
    subMenu: [
      {
        label: stepRoutes.MARITAL_STATUS.label,
        id: stepRoutes.MARITAL_STATUS.id,
        route: stepRoutes.MARITAL_STATUS.route
      },
      {
        label: stepRoutes.COBORROWER_INFORMATION.label,
        id: stepRoutes.COBORROWER_INFORMATION.id,
        route: stepRoutes.COBORROWER_INFORMATION.route
      },
      {
        label: stepRoutes.YOUR_INFORMATION.label,
        id: stepRoutes.YOUR_INFORMATION.id,
        route: stepRoutes.YOUR_INFORMATION.route
      },
      {
        label: stepRoutes.YOUR_ADDRESSES.label,
        id: stepRoutes.YOUR_ADDRESSES.id,
        route: stepRoutes.YOUR_ADDRESSES.route
      },
      {
        label: stepRoutes.LOAN_APPLICATION.label,
        id: stepRoutes.LOAN_APPLICATION.id,
        route: stepRoutes.LOAN_APPLICATION.route
      },
      {
        label: stepRoutes.EMPLOYMENT.label,
        id: stepRoutes.EMPLOYMENT.id,
        route: stepRoutes.EMPLOYMENT.route
      },
      {
        label: stepRoutes.OTHER_INCOME.label,
        id: stepRoutes.OTHER_INCOME.id,
        route: stepRoutes.OTHER_INCOME.route
      },
      {
        label: stepRoutes.REAL_ESTATE.label,
        id: stepRoutes.REAL_ESTATE.id,
        route: stepRoutes.REAL_ESTATE.route
      },
      {
        label: stepRoutes.ASSETS.label,
        id: stepRoutes.ASSETS.id,
        route: stepRoutes.ASSETS.route
      },
      {
        label: stepRoutes.GIFTS_AND_GRANTS.label,
        id: stepRoutes.GIFTS_AND_GRANTS.id,
        route: stepRoutes.GIFTS_AND_GRANTS.route
      },
      {
        label: stepRoutes.DECLARATIONS.label,
        id: stepRoutes.DECLARATIONS.id,
        route: stepRoutes.DECLARATIONS.route
      }
    ]
  },
  {
    label: stepRoutes.VERIFICATION.label,
    id: stepRoutes.VERIFICATION.id,
    route: stepRoutes.VERIFICATION.route
  },
  {
    label: stepRoutes.APPROVAL.label,
    id: stepRoutes.APPROVAL.id,
    route: stepRoutes.APPROVAL.route
  }
];

export const menuWithPricing = JSON.parse(JSON.stringify(menu));
menuWithPricing[0].subMenu = [
  ...menuWithPricing[0].subMenu,
  {
    label: stepRoutes.YOUR_PRICING.label,
    id: stepRoutes.YOUR_PRICING.id,
    route: stepRoutes.YOUR_PRICING.route
  }
];

export enum languageOptionsEnum {
  SPANISH = 'es',
  ENGLISH = 'en'
}

export enum userOptionsEnum {
  MY_ACCOUNT = 'my_account',
  SUPPORT_CENTER = 'support_center',
  LOG_OUT = 'log_out',
  MY_PERSONAL_DATA = 'my_personal_data'
}

export enum adminOptionsEnum {
  ADMIN_DATA = 'admin_data',
  MANAGE_USERS = 'manage_users',
  MANAGE_BRANCHES = 'manage_branches',
  AUDIT_LOG = 'audit_log',
  AUDIT_EMAIL_LOG = 'audit_email_log',
  RATE_QUOTE = 'rate_quote',
  PRE_APPROVALS = 'pre_approvals',
  SETTINGS = 'settings',
  PIPELINE = 'pipeline_title',
  PRICING = 'pricing',
  CALCULATOR = 'calculator',
  IMPERSONATE = 'impersonate'
}

export const languageOptions: IOptions = [
  {
    label: 'english',
    id: languageOptionsEnum.ENGLISH
  },
  {
    label: 'spanish',
    id: languageOptionsEnum.SPANISH
  }
];

export const userOptions: IOptions = [
  {
    label: 'my_account',
    id: userOptionsEnum.MY_ACCOUNT,
    link: '/my-account'
  },
  {
    label: 'log_out',
    id: userOptionsEnum.LOG_OUT
  }
];

// TODO: Re enable Branches once we support that
export const adminOptions: IOptions = [
  // {
  //   label: adminOptionsEnum.ADMIN_DATA,
  //   id: adminOptionsEnum.ADMIN_DATA,
  //   subtitle: true
  // },
  /* {
    label: adminOptionsEnum.MANAGE_BRANCHES,
    id: adminOptionsEnum.MANAGE_BRANCHES,
    link: '/branches'
  },
  {
    label: adminOptionsEnum.MANAGE_USERS,
    id: adminOptionsEnum.MANAGE_USERS,
    link: '/users'
  },
  {
    label: adminOptionsEnum.AUDIT_LOG,
    id: adminOptionsEnum.AUDIT_LOG,
    link: '/logs'
  },
  {
    label: adminOptionsEnum.AUDIT_EMAIL_LOG,
    id: adminOptionsEnum.AUDIT_EMAIL_LOG,
    link: '/email-log'
  }, */
  {
    label: 'my_account',
    id: userOptionsEnum.MY_ACCOUNT,
    link: '/my-account'
  },
  {
    label: 'pre-approvals.title',
    id: adminOptionsEnum.PRE_APPROVALS,
    link: '/admin/pre-approvals'
  },
  {
    label: 'rate-quote_table.rate-quote',
    id: adminOptionsEnum.RATE_QUOTE,
    link: '/admin/rate-quote'
  },
  {
    label: adminOptionsEnum.SETTINGS,
    id: adminOptionsEnum.SETTINGS,
    link: '/settings'
  },
  {
    label: 'log_out',
    id: userOptionsEnum.LOG_OUT
  }
  // {
  //   label: userOptionsEnum.MY_PERSONAL_DATA,
  //   id: userOptionsEnum.MY_PERSONAL_DATA,
  //   subtitle: true
  // },
];

export const userDropdownOptions = [
  adminOptionsEnum.MANAGE_BRANCHES,
  adminOptionsEnum.MANAGE_USERS,
  adminOptionsEnum.AUDIT_EMAIL_LOG,
  adminOptionsEnum.RATE_QUOTE,
  adminOptionsEnum.PRE_APPROVALS,
  adminOptionsEnum.AUDIT_LOG,
  adminOptionsEnum.SETTINGS,
  userOptionsEnum.MY_ACCOUNT,
  userOptionsEnum.LOG_OUT
];
