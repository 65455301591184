import React, { useCallback } from 'react';
import NumberFormat from 'react-number-format';
import { TextInput } from './TextInput';
import { ICurrencyProps } from './types';

export const PERCENTAGE_SYMBOL = '%';
const MAX_VAL = 90;

const withValueLimit = ({ floatValue }) => floatValue <= MAX_VAL;

export const getPercentage = (a: number, b: number) => (a / b) * 100;
export const getAmountFromPercentage = (a: number, b: number) => a * (b / 100);

export function Percentage(props: ICurrencyProps): React.ReactElement {
  const { onChange, name, value, ...rest } = props;
  const onValueChange = useCallback(
    (change) => {
      onChange?.({ target: { name, value: String(change.floatValue) || '0' } });
    },
    [name, onChange]
  );
  return (
    <NumberFormat
      prefix={''}
      suffix={PERCENTAGE_SYMBOL}
      {...(rest as unknown as any)}
      name={name}
      data-testid={name}
      value={value}
      customInput={TextInput}
      fixedDecimalScale={true}
      allowEmptyFormatting
      allowNegative={false}
      type="text"
      isAllowed={rest.isAllowed ?? withValueLimit}
      allowLeadingZeros={false}
      onValueChange={onValueChange}
    />
  );
}
