import { useAppContext } from 'context/uuids';
import Script from 'next/script';
import React, { useEffect } from 'react';
import { IRoles } from 'services/models/IRoles';

const chatEnabled = process.env.NEXT_PUBLIC_OLARK_ENABLED === 'true';

const CHAT_ROLES = chatEnabled
  ? [IRoles.OFFICER, IRoles.ADMIN, IRoles.ASSISTANT]
  : [];

type Props = {
  role?: IRoles;
};

const Chat: React.FC<Props> = ({ role }) => {
  const { profile } = useAppContext();
  useEffect(() => {
    if (!profile) return null;
    const olark = (window as any)?.olark;
    olark?.('api.box.show');

    olark?.('api.visitor.updateFullName', {
      fullName: `${profile.firstName} ${profile.lastName}`
    });
    olark?.('api.visitor.updateEmailAddress', {
      emailAddress: profile.email
    });
    return () => {
      olark?.('api.box.hide');
      olark?.('api.visitor.updateFullName', {
        fullName: `${profile.firstName} ${profile.lastName}`
      });
      olark?.('api.visitor.updateEmailAddress', {
        emailAddress: `${profile.email}`
      });
    };
  }, [profile]);

  if (!CHAT_ROLES.includes(role)) {
    return null;
  }

  return (
    profile && (
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
  ;(function(o,l,a,r,k,y){if(o.olark)return;
  r="script";y=l.createElement(r);r=l.getElementsByTagName(r)[0];
  y.async=1;y.src="//"+a;r.parentNode.insertBefore(y,r);
  y=o.olark=function(){k.s.push(arguments);k.t.push(+new Date)};
  y.extend=function(i,j){y("extend",i,j)};
  y.identify=function(i){y("identify",k.i=i)};
  y.configure=function(i,j){y("configure",i,j);k.c[i]=j};
  k=y._={s:[],t:[+new Date],c:{},l:a};
  })(window,document,"static.olark.com/jsclient/loader.js");
  olark.identify('8666-809-10-7104');
  olark('api.visitor.updateFullName', {
    fullName: "${profile.firstName} ${profile.lastName}"
  });
  olark('api.visitor.updateEmailAddress', {
    emailAddress: "${profile.email}"
  });
  `
        }}
      />
    )
  );
};

export default Chat;
