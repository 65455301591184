import { parse } from 'date-fns';
import { IBorrowerInformationFormModel } from 'models/BorrowerInformationFormModel';
import { ILoanStatus, ILoanUser } from '../models/ILoanStatus';
import { findBorrowerUser } from './loanApplication';

export function parseBorrowerInformationToForm(
  loanStatus: ILoanStatus
): IBorrowerInformationFormModel {
  const borrowerUser = findBorrowerUser(loanStatus);
  if (!borrowerUser?.borrowerInfo) return null;
  return parseGenericBorrowerInfo(borrowerUser);
}

export function parseGenericBorrowerInfo(loanUser: ILoanUser) {
  if (!loanUser?.borrowerInfo) return null;
  const presentRentAmount =
    loanUser.borrowerInfo?.housingExpenses?.presentRentAmount;
  const primaryAddressInfo = loanUser.borrowerInfo.primaryAddressInfo;
  const primaryAddress = {
    street: primaryAddressInfo?.address?.street,
    city: primaryAddressInfo?.address?.city,
    state: primaryAddressInfo?.address?.state,
    zip: primaryAddressInfo?.address?.zip,
    type: primaryAddressInfo?.address?.type
  };
  const mailingAddress = loanUser.borrowerInfo.isMailingAndPrimarySame
    ? { ...primaryAddress }
    : {
        street: loanUser.borrowerInfo.mailingAddress?.street,
        city: loanUser.borrowerInfo.mailingAddress?.city,
        state: loanUser.borrowerInfo.mailingAddress?.state,
        zip: loanUser.borrowerInfo.mailingAddress?.zip,
        type: loanUser.borrowerInfo.mailingAddress?.type
      };

  return {
    loanUserUuid: loanUser.uuid,
    birthdate: loanUser.borrowerInfo?.birthdate
      ? parse(loanUser.borrowerInfo?.birthdate, 'yyyy-MM-dd', new Date())
      : '',
    suffix: loanUser.borrowerInfo.suffix,
    ssn: loanUser.borrowerInfo.ssn,
    firstName: loanUser.borrowerInfo.firstName,
    middleName: loanUser.borrowerInfo.middleName,
    lastName: loanUser.borrowerInfo.lastName,
    email: loanUser.borrowerInfo.email,
    primaryPhone: loanUser.borrowerInfo.primaryPhone,
    shareAddressInfoWithBorrower:
      loanUser.borrowerInfo.shareAddressInfoWithBorrower,
    borrowerAddress: {
      presentRentAmount,
      isMailingAndPrimarySame: loanUser.borrowerInfo.isMailingAndPrimarySame,
      mailingAddress: mailingAddress,
      primaryAddressInfo: primaryAddressInfo,
      formerAddress: loanUser.borrowerInfo.formerAddresses
    }
  };
}

export function parseFormModelToBorrowerInfo(request): any {
  const isMailingAndPrimarySame =
    request.borrowerAddress.isMailingAndPrimarySame;
  const primaryAddress = request.borrowerAddress.primaryAddressInfo.address;
  const shareAddressInfoWithBorrower = request.shareAddressInfoWithBorrower;
  const presentRentAmount = request.presentRentAmount;
  const primaryAddressType = request.primaryAddressType;
  const mailingAddress = isMailingAndPrimarySame
    ? primaryAddress
    : request.borrowerAddress.mailingAddress;
  return {
    loanUserUuid: request.loanUserUuid,
    birthdate: request.birthdate,
    ssn: request.ssn,
    firstName: request.firstName,
    middleName: request.middleName,
    lastName: request.lastName,
    email: request.email,
    suffix: request.suffix,
    primaryPhone: request.primaryPhone,
    borrowerAddress: {
      primaryAddress,
      mailingAddress,
      isMailingAndPrimarySame,
      shareAddressInfoWithBorrower,
      primaryAddressType,
      presentRentAmount
    }
  };
}
