import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';

export const tagTypes = [
  'LOAN',
  'OFFICERS',
  'SETTINGS',
  'PURPOSE',
  'PROFILE',
  'DOCUMENTS',
  'ADMIN_USERS',
  'ADMIN_USER',
  'ADMIN_EMAILS',
  'ADMIN_BRANCHES',
  'ADMIN_RATE_QUOTE',
  'ADMIN_PRE_APPROVALS',
  'BRANCHES',
  'PIPELINE',
  'PIPELINE_DETAILS',
  'TASKS',
  'LOANS',
  'LOGS',
  'ADMIN_BYTE_LOGS'
];

export const baseApi = createApi({
  reducerPath: 'api',
  baseQuery,
  keepUnusedDataFor: 0,
  endpoints: () => ({}),
  tagTypes: tagTypes,
  refetchOnReconnect: true
});
