import * as yup from 'yup';
import { IValidationTrans } from './types';

export const ChangePasswordValidation: IValidationTrans = (t) => {
  const requiredFieldText = t('validation.field_required');

  return yup.object({
    email: yup.string().email().required(requiredFieldText)
  });
};

export const RedefinePasswordValidation: IValidationTrans = (t) => {
  const requiredFieldText = t('validation.field_required');
  const requiredPasswordMustMatch = t('validation.passwords_must_match');

  return yup.object({
    current: yup.string().required(requiredFieldText),
    password: yup
      .string()
      .required(requiredFieldText)
      .oneOf([yup.ref('current'), null], requiredPasswordMustMatch)
  });
};

export const LoginValidation: IValidationTrans = (t) => {
  const requiredFieldText = t('validation.field_required');

  return yup.object({
    email: yup.string().required(requiredFieldText),
    password: yup.string().required(requiredFieldText)
  });
};

export const CreateAccountValidation: IValidationTrans = (t) => {
  const requiredFieldText = t('validation.field_required');
  const requiredPhoneRequired = t('validation.phone_required');
  const requiredEmailRequired = t('validation.email_must_match');
  const terms = t('validation.terms');

  return yup.object({
    first_name: yup.string().required(requiredFieldText),
    last_name: yup.string().required(requiredFieldText),
    phone_primary: yup
      .string()
      .length(14, requiredPhoneRequired)
      .required(requiredFieldText),
    email: yup.string().email().required(requiredFieldText),
    confirm_email: yup
      .string()
      .required(requiredFieldText)
      .oneOf([yup.ref('email'), null], requiredEmailRequired),
    choose_password: yup.string().required(requiredFieldText),
    terms: yup.boolean().required().oneOf([true], terms)
  });
};
