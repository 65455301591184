export const stepRoutes = {
  START: {
    label: 'menu.start',
    id: 'START',
    moduleKey: 'start'
  },
  SELECT_OFFICER: {
    label: 'menu.select_officer',
    id: 'SELECT_OFFICER',
    route: '/start',
    sessionFormkey: 'select-officer'
  },
  ABOUT_THE_LOAN: {
    label: 'menu.about',
    id: 'ABOUT_THE_LOAN',
    route: '/start/about-the-loan',
    sessionFormkey: 'about-the-loan'
  },
  INFORMATION: {
    label: 'menu.information',
    id: 'INFORMATION',
    moduleKey: 'information'
  },
  MARITAL_STATUS: {
    label: 'menu.marital_status',
    id: 'MARITAL_STATUS',
    route: '/information/marital-status',
    sessionFormkey: 'marital-status'
  },
  COBORROWER_INFORMATION: {
    label: 'menu.co_borrowers',
    id: 'COBORROWER_INFORMATION',
    route: '/information/coborrower-information',
    sessionFormkey: 'coborrower-information'
  },
  YOUR_INFORMATION: {
    label: 'menu.your_information',
    id: 'YOUR_INFORMATION',
    route: '/information/your-information',
    sessionFormkey: 'your-information'
  },
  YOUR_PRICING: {
    label: 'your_pricing.title',
    id: 'YOUR_PRICING',
    route: '/information/your-pricing',
    sessionFormkey: 'your-pricing'
  },
  YOUR_ADDRESSES: {
    label: 'menu.your_addresses',
    id: 'YOUR_ADDRESSES',
    route: '/information/your-address',
    sessionFormkey: 'your-addresses'
  },
  LOAN_APPLICATION: {
    label: 'menu.loan_application',
    id: 'LOAN_APPLICATION',
    route: '/information/loan-application',
    sessionFormkey: 'loan-application'
  },
  EMPLOYMENT: {
    label: 'menu.employment',
    id: 'EMPLOYMENT',
    route: '/information/employment',
    sessionFormkey: 'employment'
  },
  OTHER_INCOME: {
    label: 'menu.income',
    id: 'OTHER_INCOME',
    route: '/information/other-income',
    sessionFormkey: 'other-income'
  },
  REAL_ESTATE: {
    label: 'menu.real_estate',
    id: 'REAL_ESTATE',
    route: '/information/real-estate',
    sessionFormkey: 'real-estate'
  },
  ASSETS: {
    label: 'menu.assets',
    id: 'ASSETS',
    route: '/information/your-assets',
    sessionFormkey: 'assets'
  },
  GIFTS_AND_GRANTS: {
    label: 'menu.gifts',
    id: 'GIFTS_AND_GRANTS',
    route: '/information/gifts-and-grants',
    sessionFormkey: 'gifts-and-grants'
  },
  DECLARATIONS: {
    label: 'menu.declarations',
    id: 'DECLARATIONS',
    route: '/information/declarations',
    sessionFormkey: 'declarations'
  },
  VERIFICATION: {
    label: 'menu.verification',
    id: 'VERIFICATION',
    route: '/verification',
    moduleKey: 'verification'
  },
  APPROVAL: {
    label: 'menu.approval',
    id: 'APPROVAL',
    route: '/submission',
    moduleKey: 'approval'
  },
  LOGIN_BORROWER: {
    label: 'LOGIN',
    id: 'LOGIN',
    route: '/borrower/login',
    moduleKey: 'login'
  },
  LANDING: {
    label: 'LANDING',
    id: 'LANDING',
    route: '/',
    moduleKey: 'landing'
  },
  LOGIN_OFFICER: {
    label: 'LOGIN_OFFICER',
    id: 'LOGIN_OFFICER',
    route: '/officer/login',
    moduleKey: 'login'
  },
  SIGN_UP: {
    label: 'LOGIN',
    id: 'LOGIN',
    route: '/borrower/create_account',
    moduleKey: 'login'
  },
  CHANGE_PASSWORD_OFFICER: {
    label: 'CHANGE_PASSWORD',
    id: 'CHANGE_PASSWORD',
    route: '/borrower/change_password',
    moduleKey: 'login'
  },
  CHANGE_PASSWORD_BORROWER: {
    label: 'CHANGE_PASSWORD',
    id: 'CHANGE_PASSWORD',
    route: '/borrower/change_password',
    moduleKey: 'login'
  },
  MY_LOAN: {
    label: 'MY_LOAN',
    id: 'MY_LOAN',
    route: '/my-loan',
    moduleKey: 'my-loan'
  },
  MY_LOANS: {
    label: 'MY_LOANS',
    id: 'MY_LOANS',
    route: '/my-loans',
    moduleKey: 'my-loans'
  },
  ADD_BRANCH: {
    label: 'ADD_BRANCH',
    id: 'ADD_BRANCH',
    route: '/branch/create',
    moduleKey: 'branch-create'
  },
  BRANCHES: {
    label: 'BRANCHES',
    id: 'BRANCHES',
    route: '/settings/branches',
    moduleKey: 'branches'
  },
  USERS: {
    label: 'USERS',
    id: 'USERS',
    route: '/settings/users',
    moduleKey: 'users'
  },
  AUDIT_LOGS: {
    label: 'AUDIT_LOGS',
    id: 'AUDIT_LOGS',
    route: '/settings/logs',
    moduleKey: 'logs'
  },
  LOAN_LOGS: {
    label: 'LOAN_LOGS',
    id: 'LOAN_LOGS',
    route: '/settings/loan_logs',
    moduleKey: 'loan_logs'
  },
  EMAIL_LOGS: {
    label: 'EMAIL_LOGS',
    id: 'EMAIL_LOGS',
    route: '/settings/email-log',
    moduleKey: 'email-log'
  },
  EDIT_EMPLOYMENTS: {
    label: 'EDIT_EMPLOYMENTS',
    id: 'EDIT_EMPLOYMENTS',
    route: '/edit_employments',
    moduleKey: 'edit_employments'
  },
  EDIT_EXPENSES: {
    label: 'EDIT_EXPENSES',
    id: 'EDIT_EXPENSES',
    route: '/edit_expenses',
    moduleKey: 'edit_expenses'
  },
  EDIT_INCOMES: {
    label: 'EDIT_INCOMES',
    id: 'EDIT_INCOMES',
    route: '/edit_incomes',
    moduleKey: 'edit_incomes'
  },
  EDIT_LIABILITIES: {
    label: 'EDIT_LIABILITIES',
    id: 'EDIT_LIABILITIES',
    route: '/edit_liabilities',
    moduleKey: 'edit_liabilities'
  }
};
