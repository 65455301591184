import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/system';
import React from 'react';
import { textInputStyles } from 'styles/textInputStyles';
import { IProps } from './types';

export const BootstrapTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      arrow
      classes={{ popper: className }}
      enterTouchDelay={50}
      leaveTouchDelay={3000}
    />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltipPlacementTop} .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
    bottom: '1px'
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black
  }
}));

export function TextInput({
  tooltipMessage,
  inputAdorment,
  ...props
}: IProps): React.ReactElement {
  const inputPropManager = inputAdorment
    ? {
        startAdornment: (
          <InputAdornment position="start">{inputAdorment}</InputAdornment>
        ),
        endAdornment: tooltipMessage && (
          <InputAdornment position="end">
            <BootstrapTooltip title={tooltipMessage} arrow placement="top">
              <IconButton>
                <InfoOutlinedIcon />
              </IconButton>
            </BootstrapTooltip>
          </InputAdornment>
        )
      }
    : {
        endAdornment: tooltipMessage && (
          <InputAdornment position="end">
            <BootstrapTooltip title={tooltipMessage} arrow placement="top">
              <IconButton>
                <InfoOutlinedIcon />
              </IconButton>
            </BootstrapTooltip>
          </InputAdornment>
        )
      };

  return (
    <TextField
      variant="filled"
      data-testid={props.name}
      fullWidth
      size="small"
      InputProps={inputPropManager}
      InputLabelProps={{
        sx: tooltipMessage
          ? {
              ...textInputStyles.labelColor,
              pr: 6.5
            }
          : textInputStyles.labelColor
      }}
      value={props.value || ''}
      {...props}
    />
  );
}
