import { ICoBorrowerInformationFormModel } from 'models/CoBorrowerInformationFormModel';
import { ILoanStatus } from '../models/ILoanStatus';
import { findBorrowerUser } from './loanApplication';

export function parseCoBorrowerInformationToForm(
  loanStatus: ILoanStatus
): ICoBorrowerInformationFormModel {
  const borrowerUser = findBorrowerUser(loanStatus);
  if (!borrowerUser.borrowerInfo) return null;
}

export function parseFormModelToCoBorrowerInfo(request): any {
  const primaryAddress = request.borrowerAddress.primaryAddressInfo.address;
  const mailingAddress = request.borrowerAddress.isMailingAndPrimarySame
    ? request.borrowerAddress.primaryAddressInfo.address
    : request.borrowerAddress.mailingAddress;
  return {
    birthdate: request.birthdate,
    ssn: request.ssn,
    firstName: request.firstName,
    middleName: request.middleName,
    lastName: request.lastName,
    email: request.email,
    primaryPhone: request.primaryPhone,
    borrowerAddress: {
      primaryAddress,
      mailingAddress
    }
  };
}
