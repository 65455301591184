/* eslint-disable complexity */
import * as Sentry from '@sentry/nextjs';
import { useAppContext } from 'context/uuids';
import cookieCutter from 'cookie-cutter';
import { useFormik } from 'formik';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  useLazyGetLoansQuery,
  useLazyGetPendingLoanQuery
} from 'services/loan';
import { useGoogleLoginMutation, useLoginMutation } from 'services/login';
import { ILoanStatus } from 'services/models/ILoanStatus';
import { IGoogleLoginResponse } from 'services/models/ILogin';
import { IRoles } from 'services/models/IRoles';
import { useLazyGetProfileQuery } from 'services/profile';
import { setCredentials } from 'services/slice/authSlice';
import { getCurrentStep } from 'services/utils';
import { stepRoutes } from 'constants/step-routes';
import { LoginValidation } from 'validations/LoginValidation';

export function getLoanStatus(loans: ILoanStatus[]) {
  const hasLoanStarted = loans?.find(
    (loan) => loan.loanStatus === 'lead' && !loan.completedAt
  );
  const hasCompletedLoan = loans?.every((loan) => !!loan.completedAt);
  const hasZeroLoans = loans?.length === 0;
  return {
    hasLoanStarted,
    hasZeroLoans,
    hasCompletedLoan
  };
}

export function useLogin() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();
  const [login] = useLoginMutation();
  const [googleLogin] = useGoogleLoginMutation();
  const { setLoanUuid } = useAppContext();

  const [getProfile] = useLazyGetProfileQuery();
  const [getLoans] = useLazyGetLoansQuery();
  const [getPendingLoan] = useLazyGetPendingLoanQuery();

  useEffect(() => {
    if (router.pathname === '/officer/login') {
      if (router.query.loan) {
        router.prefetch(`/pipeline/${router.query.loan}`);
      } else {
        router.prefetch('/pipeline');
      }
    }
  }, [router]);

  const processLogin = useCallback(async () => {
    const profile = await getProfile().unwrap();

    Sentry.setUser({ email: profile?.email, ip_address: '{{auto}}' });

    const loans = await getLoans({
      uuid: profile?.uuid
    }).unwrap();

    const { hasZeroLoans, hasLoanStarted, hasCompletedLoan } =
      getLoanStatus(loans);

    cookieCutter.set('currentRole', profile.role, {
      maxAge: 30 * 24 * 60 * 60,
      path: '/',
      sameSite: 'None',
      secure: true
    });

    let pipelineUrl = '/pipeline';
    if (router.query.loan) {
      pipelineUrl = `/pipeline/${router.query.loan}`;
    }

    switch (profile?.role) {
      case IRoles.OFFICER:
        router.push(pipelineUrl);
        return;
      case IRoles.ADMIN:
        router.push(pipelineUrl);
        return;
      case IRoles.ASSISTANT:
        router.push(pipelineUrl);
        return;
    }

    if (hasZeroLoans || hasLoanStarted) {
      const pendingLoan = await getPendingLoan({
        uuid: hasLoanStarted?.uuid
      }).unwrap();
      const step = getCurrentStep(pendingLoan, false);
      router.push(step.route ?? stepRoutes.SELECT_OFFICER.route);
    } else if (hasCompletedLoan) {
      router.push(stepRoutes.MY_LOANS.route);
    } else {
      router.push(stepRoutes.MY_LOANS.route);
    }
  }, [getLoans, getPendingLoan, getProfile, router]);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: LoginValidation(t),
    validateOnMount: true,
    onSubmit: async ({ email, password }, helper) => {
      try {
        helper.setSubmitting(true);
        setLoanUuid('');
        dispatch(setCredentials({ accessToken: null, refreshToken: null }));

        const loginResponse = await login({
          email,
          password
        }).unwrap();

        await dispatch(
          setCredentials({
            accessToken: loginResponse.accessToken,
            refreshToken: loginResponse.refreshToken
          })
        );
        await processLogin();
      } catch (error) {
        enqueueSnackbar(t('errors.email_password_wrong'), {
          variant: 'error',
          autoHideDuration: 400
        });
      } finally {
        helper.setSubmitting(false);
      }
    }
  });

  const onSuccessGoogleSignIn = useCallback(
    async (googleLoginResponse: IGoogleLoginResponse) => {
      try {
        const loginResponse = await googleLogin({
          credential: googleLoginResponse.credential
        }).unwrap();

        await dispatch(
          setCredentials({
            accessToken: loginResponse.accessToken,
            refreshToken: loginResponse.refreshToken
          })
        );

        await processLogin();
      } catch (error) {
        enqueueSnackbar(t('errors.email_password_wrong'), {
          variant: 'error',
          autoHideDuration: 400
        });
      }
    },
    [dispatch, enqueueSnackbar, googleLogin, processLogin, t]
  );

  return {
    onSuccessGoogleSignIn,
    formik
  };
}
